<template>
  <div class="layout">
    <div class="business-info cl">
      <div class="cover">
        <img class="img" :src="business.photoUrl"/>
      </div>
      <div class="infos">
        <div class="name">{{business.businessName}}</div>
        <div class="m-t">{{$t('Business Profile')}}</div>
        <div class="p">{{business.introduction}}</div>
        <div class="m-t">{{$t('Business Contact')}}</div>
        <div class="p">
          {{$t('Contact Number')}}：{{business.phoneType|phonePrefixer}} {{business.phone}}
        </div>
        <div class="p" v-if="business.email">{{$t('Email')}}：{{business.email}}</div>
        <div class="p" v-for="item of business.contacts" :key="item.businessId">
          <div v-if="item.phoneType =='WhatsApp'">
            <a :href='getURL("https://wa.me/852"+item.userPhone)'>WhatsApp</a>
          </div>
          <div v-else>
          {{item.phoneType}}：{{item.userPhone}}
        </div>
      </div>
    </div>
    <div class="main-content cl">
      <div class="product-list">
        <div class="filter cl">
          <div class="fl item cl">
            <div class="label">{{$t('search')}}：</div>
            <div class="fl">
              <input class="ipt" type="text" v-model="keyword" autocomplete="off" :placeholder="$t('PleaseEnter')"/>
            </div>
          </div>
          <div>
            <div class="label">{{$t('Sort')}}：</div>
            <el-select class="fl select" v-model="orderType" size="small" :placeholder="$t('PleaseChoose')">
              <el-option v-for="item in sortOptions"
                         :key="item.value"
                         :label="item.label"
                         :value="item.value">
              </el-option>
            </el-select>
          </div>
        </div>
        <div class="cl">
          <theme-travel-item target="_blank"
                             v-for="(item,index) of list"
                             :datas="item"
                             :index="index"
                             :list.sync="list"
                             :key="item.productId"/>
        </div>
        <el-pagination class="pages"
                       background
                       layout="prev, pager, next"
                       :page-count="totalPages"
                       @current-change="changePage"
                       hide-on-single-page>
        </el-pagination>
        <empty v-if="isEmpty" :title="$t('NoData')"/>
      </div>
      <div class="news-list">
        <router-link class="news-item" target="_blank"
                     v-for="item of news"
                     :to="{name:'AdminBusinessNewsDetail',params:{id:item.newsId}}"
                     :key="item.newsId">
          <div class="cover">
            <img class="img" :src="item.photoUrl"/>
          </div>
          <div class="infos">
            <div class="title" :title="item.title">{{item.title}}</div>
          </div>
        </router-link>
      </div>
      <div class="gap-line"></div>
    </div>
  </div>
  </div>
</template>
<script>
  import ThemeTravelItem from '../../../components/ThemeTravelItem';
  import {getBusinessDetail, getBusinessProductList} from '../../../api/admin-business';
  import Empty from '../../../components/Empty';

  export default {
    name: 'AdminBusinessDetail',
    data() {
      return {
        userId: '',
        sortOptions: [
          {value: 'asc', label: this.$t('PriceFromLowToHigh')},
          {value: 'desc', label: this.$t('PriceFromHighToLow')}
        ],
        keyword: '',
        orderType: '',
        business: {},
        news: [],
        list: [],
        totalPages: 1,
        isEmpty: false
      };
    },
    components: {Empty, ThemeTravelItem},
    created() {
      this.userId = this.$route.params.id;
      this.getDeatil();
      this.getList();
    },
    methods: {
      getURL(url) {
        url = JSON.stringify(url);
        url = url.replaceAll('"', '');
          return url.replace(' ', '');
      },
      // 获取详情
      getDeatil() {
        getBusinessDetail(this.userId).then(res => {
          const value = res.value || {};
          this.business = value.business || {};
          this.news = value.news || [];
        });
      },
      // 获取产品列表
      getList(page = 1) {
        const data = {page, perPage: 10, personalUserId: this.$store.getters.userId, keyword: this.keyword, orderType: this.orderType};
        getBusinessProductList(this.userId, data).then(res => {
          const {value} = res;
          const list = value.list || [];
          this.list = list;
          this.totalPages = value.totalPages;
          this.isEmpty = data.page === 1 && !this.list.length;
        });
      },
      changePage(page) {
        this.getList(page);
      }
    },
    watch: {
      keyword: function (value) {
        this.getList();
      },
      orderType: function (value) {
        this.getList();
      }
    }
  };
</script>
<style scoped lang="less">
  .business-info{
    padding:24px 0;border-bottom:1px solid #d9d9d9;
    .cover{float:left;width:90px;height:90px;overflow:hidden;border-radius:50%;}
    .infos{margin-left:120px;}
    .name{color:#000;font-size:20px;}
    .p{margin-top:8px;color:#999;}
    .m-t{margin-top:24px;}
  }
  .main-content{
    position:relative;
    .gap-line{position:absolute;top:0;left:280px;bottom:0;width:1px;background-color:#d9d9d9;}
  }
  .product-list{
    float:left;width:100%;padding-left:300px;
    .filter{
      margin:20px 0;
      .item{margin-right:24px;}
      .label{float:left;line-height:32px;}
    }
    .ipt{
      width:220px;height:32px;padding:0 10px;border:1px solid #d9d9d9;border-radius:4px;
      &:focus{border-color:#ff6f61;}
    }
    .select{width:220px;}
    /deep/ .theme-item{width:210px;}
  }
  .news-list{float:left;width:260px;margin-left:-100%;}
  .news-item{
    display:block;margin-top:20px;overflow:hidden;color:#321908;cursor:pointer;border-radius:4px;box-shadow:0 2px 9px 0 rgba(255, 115, 101, 0.2);
    .cover{height:178px;}
    .infos{padding:12px;}
    .title{overflow:hidden;white-space:nowrap;text-overflow:ellipsis;font-size:16px;}
  }
</style>
